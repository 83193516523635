@charset "UTF-8";

.home header {
  background-color: transparent;

  #nav > ul > li > a {
    position: relative;
    z-index: 1;
    color: #fff;
    text-decoration: none;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 700;
  }
}

#mv {
  height: 50vw;
  max-height: 80rem;

  .inner {
    position: relative;
    z-index: 99999;
    margin-top: -29rem;
  }

  h2 {
    color: #d6011b;
    line-height: 1.18;
    font-size: 10rem;
    font-family: 'Heebo', sans-serif;
    letter-spacing: .15em;
  }

  h3 {
    position: relative;
    margin-top: 3rem;
    font-size: 4rem;
    font-weight: 700;
    font-family: 'Noto Sans JP', sans-serif;
    letter-spacing: .25em;

    small {
      opacity: 1;
      font-size: 3.5rem;
      font-weight: 700;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      width: calc(100% - 36.125rem);
      height: 1px;
      background-color: #000;
    }
  }
}

#mv-pg {
  position: relative;
  width: 100%;
  height: 50vw;
  max-height: 80rem;
  overflow: hidden;

  li {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: inherit;
    max-height: 80rem;
    overflow: hidden;

    img {
      width: 100%;
      -webkit-transform-origin: center top;
      transform-origin: center top;
    }
  }
}

#video {
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  video {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

#particles-js {
  position: absolute;
  z-index: 99998;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vw;
  max-height: 80rem;
}

#mv-link {
  position: absolute;
  right: 2rem;
  width: 30vw;
  max-width: 54rem;
  margin-top: -36rem;

  li {
    float: left;

    &:nth-child(1) {
      width: 100%;
      margin-bottom: 2rem;
    }

    &:nth-child(2) {
      width: calc(50% - 1rem);
      margin-right: 2rem;
    }

    &:nth-child(3) {
      width: calc(50% - 1rem);
    }

    a {
      position: relative;
      overflow: hidden;
      display: block;
      padding: 2rem 0;
      border: solid 1px #fff;
      color: #fff;
      text-decoration: none;
      text-align: center;
      line-height: 1;
      font-size: 1.5rem;
      font-weight: 700;

      span {
        position: relative;
        z-index: 1;
        display: inline-block;
      }
    }

    &:nth-child(1) a {
      border: solid 5px #fff;
      font-size: 2.4rem;
    }

    a {
      &:before {
        content: "";
        width: 1rem;
        background-color: #fff;
        height: 1rem;
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        z-index: 0;
        transition: all .2s ease-in;
      }

      &:hover {
        opacity: 1;
        color: #333;

        &:before {
          opacity: 1;
          -webkit-transform: scale(50, 50);
          transform: scale(50, 50);
        }
      }
    }

    &:nth-child(1) a:hover:before {
      -webkit-transform: scale(70, 70);
      transform: scale(70, 70);
    }
  }
}

@media screen and (max-width: 1024px) {
  #mv {
    .inner {
      margin-top: -29.2vw;
    }

    h2 {
      font-size: 9.75vw;
    }
  }

  #mv-link li {
    a {
      font-size: 1.4rem;
    }

    &:nth-child(1) {
      a {
        font-size: 2rem;
      }

      margin-bottom: 1rem;
    }

    &:nth-child(2) {
      width: calc(50% - .5rem);
      margin-right: 1rem;
    }

    &:nth-child(3) {
      width: calc(50% - .5rem);
    }
  }
}

@media screen and (max-width: 768px) {
  #mv {
    .inner {
      overflow: hidden;
    }

    height: 80vw;
  }

  #mv-pg {
    height: 80vw;

    li {
      height: 80vw;
    }
  }

  #particles-js {
    height: 80vw;
  }

  #mv-pg li img {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }

  #video video {
    display: none;
  }

  #mv-link {
    position: relative;
    right: auto;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    padding-top: 4rem;

    li {
      a {
        padding: 3rem 0;
        border: solid 1px #333;
        color: #333;
      }

      &:nth-child(1) a {
        border: solid 5px #333;
      }

      a {
        &:before {
          background-color: #333;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  #mv h3 {
    margin-top: 1rem;
    font-size: 3.5rem;

    small {
      font-size: 3rem;
    }

    &:before {
      width: calc(100% - 30rem);
    }
  }
}

#about {
  position: relative;
  overflow: hidden;

  &:before, &:after {
    position: absolute;
    content: "";
    opacity: 0;
    transition: all .7s ease-out;
  }

  &:before {
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    background: rgba(133, 99, 34, 0.06);
    -webkit-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
  }

  &:after {
    bottom: 0;
    right: 0;
    width: 12.5%;
    height: 100%;
    background: rgba(133, 99, 34, 0.1);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
  }

  &.aos-animate {
    &:before {
      width: 50%;
      opacity: 1;
      -webkit-transform: skew(60deg);
      transform: skew(60deg);
    }

    &:after {
      width: 25%;
      opacity: 1;
      -webkit-transform: skew(-30deg);
      transform: skew(-30deg);
    }
  }

  .inner {
    padding-top: 33rem;
  }

  h2 {
    max-width: 80rem;
    margin: 0 auto;
    margin-bottom: 6rem;
    text-align: center;
  }

  p {
    color: #666;
    font-weight: 700;
  }

  .tx {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 768px) {
  #about {
    .inner {
      padding-top: 40rem;
    }

    h2 {
      padding: 0 4rem;
    }
  }
}

#research {
  position: relative;
  background: #bb102e;
  background: -moz-linear-gradient(left, #bb102e 0%, #d60131 50%, #ed7836 100%);
  background: -webkit-linear-gradient(left, #bb102e 0%, #d60131 50%, #ed7836 100%);
  background: linear-gradient(to right, #bb102e 0%, #d60131 50%, #ed7836 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb102e', endColorstr='#ed7836', GradientType=1);

  &:before, &:after {
    position: absolute;
    content: "";
    opacity: 0;
    transition: all .7s ease-out;
  }

  &:before {
    top: 0;
    left: 0;
    width: 12.5%;
    height: 100%;
    background: rgba(0, 0, 0, 0.06);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
  }

  &:after {
    bottom: 0;
    left: 0;
    width: 25%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
  }

  &.aos-animate {
    &:before {
      width: 25%;
      opacity: 1;
      -webkit-transform: skew(-30deg);
      transform: skew(-30deg);
    }

    &:after {
      width: 50%;
      opacity: 1;
      -webkit-transform: skew(60deg);
      transform: skew(60deg);
    }
  }

  .research-link {
    position: relative;
    z-index: 1;

    li {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }

    div {
      &:nth-child(1) li:before {
        background-image: url(../images/index/img_1.jpg);
      }

      &:nth-child(2) li:before {
        background-image: url(../images/index/img_2.jpg);
      }

      &:nth-child(3) li:before {
        background-image: url(../images/index/img_3.jpg);
      }

      &:nth-child(4) li:before {
        background-image: url(../images/index/img_4.jpg);
      }
    }

    li a {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      color: #333;
      background-color: #fff;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        opacity: 1;
        background-color: transparent;
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;

        &.t {
          top: 50%;
          color: #d6011b;
          font-size: 2.2rem;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }

      &:hover span.t {
        color: #fff;
      }

      span.more {
        bottom: 3rem;
      }

      &:hover span.more {
        color: #fff;
      }
    }
  }
}

#people {
  position: relative;
  overflow: hidden;

  &:before, &:after {
    position: absolute;
    content: "";
    opacity: 0;
    transition: all .7s ease-out;
  }

  &:before {
    top: 0;
    right: 0;

    /* width: 25%; */

    height: 100%;
    background: rgba(133, 99, 34, 0.06);
    -webkit-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
    width: 50%;
    opacity: 1;
    -webkit-transform: skew(60deg);
    transform: skew(60deg);
  }

  &:after {
    bottom: 0;
    right: 0;
    width: 12.5%;
    height: 100%;
    background: rgba(133, 99, 34, 0.1);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    width: 25%;
    opacity: 1;
    -webkit-transform: skew(-30deg);
    transform: skew(-30deg);
  }

  .people-link {
    position: relative;
    z-index: 1;

    li {
      overflow: hidden;

      a {
        position: relative;
        display: block;
        text-decoration: none;

        &:hover {
          opacity: 1;
        }

        img {
          transition: all 3s linear;
        }

        &:hover img {
          -webkit-transform: scale(1.2, 1.2) rotate(0.1deg);
          transform: scale(1.2, 1.2) rotate(0.1deg);
        }

        .img {
          transition: all .3s ease-out;
        }

        &:hover .img {
          opacity: .4;
        }
      }
    }

    .tw {
      position: absolute;
      top: 50%;
      display: block;
      width: 100%;
      text-align: center;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      .t {
        color: #fff;
        font-size: 2.4rem;
        font-weight: 700;
        transition: all .3s ease-out;

        small {
          display: block;
          font-size: 1.6rem;
          font-weight: 700;
        }
      }
    }

    a:hover .tw .t {
      color: #333;
    }

    .more {
      position: absolute;
      bottom: 2rem;
      display: block;
      width: 100%;
      color: #fff;
      text-align: center;
      font-weight: 700;
      transition: all .3s ease-out;
    }

    a:hover .more {
      color: #333;
    }
  }
}

#recruit {
  position: relative;
  background-image: url(../common/images/recruit_bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .inner {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 100%;
      background: #d6011b;
      transition: all .7s ease-out;
      transform-origin: left bottom;
      width: 50%;
      opacity: .6;
      transform: skew(40deg);
      display: block !important;
    }

    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  h2 {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 4rem;
    font-family: 游明朝, YuMincho, "Noto Serif JP", "ヒラギノ明朝 ProN W6", "Hiragino Mincho ProN", HiraMinProN-W6, HG明朝E, "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "Times New Roman", Meiryo, serif;
    letter-spacing: .15em;

    small {
      opacity: 1;
      font-size: 3.2rem;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1024px) {
  #recruit h2 {
    font-size: 3rem;
    letter-spacing: .1em;

    small {
      font-size: 2.2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  #recruit h2 {
    text-align: center;
  }
}

#info {
  .h2 {
    margin-bottom: 1em;
  }

  .inner {
    width: 100%;

    /* max-width: 64rem; */

    padding-left: 4rem;
    padding-right: 4rem;
  }

  .list-wrap {
    margin-bottom: 4rem;
    -webkit-overflow-scrolling: touch;
    display: flex;

    a {
      display: block;
      width: calc(50% - 20px);
      color: #333;
      text-decoration: none;
      border: 1px solid #cfcfcf;

      &:nth-of-type(2n) {
        margin-left: auto;
      }

      .blogBox {
        .boxHead {
          display: flex;
          padding: 0 29px 15px 0;
          border-bottom: 1px solid #d88785;

          .date {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #bb3127;
            color: #fff;
            font-size: .9em;
            flex-shrink: 0;
            line-height: 1.4;

            span {
              font-size: 1.6em;
              font-weight: 700;
            }
          }

          .text {
            margin-left: 30px;
            flex-grow: 1;
            line-height: 1.4;

            .icon {
              display: flex;
              margin: -1px 0 20px;
              justify-content: flex-end;

              li {
                display: inline-flex;
                align-items: center;
                height: 33px;
                padding: 0 15px;
                background-color: #e6e0d8;
                margin-left: 5px;
                font-size: .8em;
              }
            }

            p {
              font-size: 24px;
              font-weight: 700;
              text-align: left;
            }
          }
        }

        .boxCont {
          padding: 24px 32px;
          display: flex;

          .right {
            width: 100px;
            flex-shrink: 0;
            margin-left: 28px;

            p {
              text-align: center;
              font-size: .9em;
              margin-top: 3px;
            }
          }
        }
      }
    }
  }

  .jspVerticalBar {
    width: .5rem;
    background-color: transparent;
  }

  .jspDrag {
    background-color: #666;
  }

  .jspHorizontalBar {
    display: none;
  }

  .d {
    padding-right: 1rem;
  }

  .list li:nth-child(odd) {
    background-color: transparent;
  }
}

#info-blog {
  .jspTrack {
    background-color: #eee;
  }

  .btw .icon-lmp-other-win {
    font-size: 2.2rem;
  }
}

/* #info-blog .inner { float: right } */

#info-article {
  background: rgba(133, 99, 34, 0.1);

  .jspTrack {
    background-color: #fff;
  }
}

@media screen and (max-width: 768px) {
  #info .inner {
    width: 100%;
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    .list-wrap {
      flex-direction: column;

      a {
        width: 100%;
        margin-bottom: 2em;

        .blogBox .boxHead {
          padding-right: .5rem;

          .text {
            margin-left: 2.4rem;
          }
        }
      }
    }
  }
}
